import { string, z } from 'zod'

const validationschema = (t) => {
  const PersonalInformation_firstNameRequiredErrorMessage = t('PersonalInformation_firstNameRequiredErrorMessage')
  const PersonalInformation_firstNameInvalidMessage = t('PersonalInformation_firstNameInvalidMessage')
  const PersonalInformation_lastNameRequiredErrorMessage = t('PersonalInformation_lastNameRequiredErrorMessage')
  const PersonalInformation_lastNameInvalidMessage = t('PersonalInformation_lastNameInvalidMessage')
  const PersonalInformation_phoneRequiredErrorMessage = t('PersonalInformation_phoneRequiredErrorMessage')
  const PersonalInformation_phoneInvalidMessage = t('PersonalInformation_phoneInvalidMessage')
  const PersonalInformation_dateOfBirthRequiredErrorMessage = t('PersonalInformation_dateOfBirthRequiredErrorMessage')
  const PersonalInformation_dateOfBirthInvalidMessage = t('PersonalInformation_dateOfBirthInvalidMessage')
  const PersonalInformation_clinicianRequiredErrorMessage = t('PersonalInformation_clinicianRequiredErrorMessage')
  const PersonalInformation_clinicianInvalidMessage = t('PersonalInformation_clinicianInvalidMessage')
  const PersonalInformation_pregnancyStatusRequiredErrorMessage = t('PersonalInformation_pregnancyStatusRequiredErrorMessage')
  const PersonalInformation_pregnancyStatusInvalidMessage = t('PersonalInformation_pregnancyStatusInvalidMessage')
  const PersonalInformation_emailRequiredErrorMessage = t('PersonalInformation_emailRequiredErrorMessage')
  const PersonalInformation_emailInvalidMessage = t('PersonalInformation_emailInvalidMessage')
  const PersonalInformation_emailSpecialCharactersErrorMessage = t('PersonalInformation_emailSpecialCharactersErrorMessage')


  return ({
    clinician: z.string({
      required_error: PersonalInformation_clinicianRequiredErrorMessage
    }).min(2),

    contactMethod: string().trim().min(3),

    pregnancyStatus: z.string({
      required_error: PersonalInformation_pregnancyStatusRequiredErrorMessage
    }).min(3).refine((value) => /^[a-zA-Z]+([-'\s]?[a-zA-Z]+)*$/.test(value), PersonalInformation_pregnancyStatusInvalidMessage),

    email: z.string({
      required_error: PersonalInformation_emailRequiredErrorMessage
    })
      .email({
        message: (
          <div style={{ lineHeight: '1.5' }}>
            {PersonalInformation_emailInvalidMessage} <br />
            {PersonalInformation_emailSpecialCharactersErrorMessage}
          </div>
        )
      })
      .refine((value) => {
        const dotRules = /^[^.].*[^.]$/;
        const consecutiveDotsRule = !/\.\./.test(value);
        const specialCharRule = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);

        return dotRules.test(value) && consecutiveDotsRule && specialCharRule;
      }, {
        message: (
          <div style={{ lineHeight: '1.5' }}>
            {PersonalInformation_emailInvalidMessage} <br />
            {PersonalInformation_emailSpecialCharactersErrorMessage}
          </div>
        )
      }),

    firstName: z
      .string({
        required_error: PersonalInformation_firstNameRequiredErrorMessage
      })
      .min(2)
      .refine(
        (value) => /^[a-zA-Z]+([-'\s]?[a-zA-Z]+)*$/.test(value),
        PersonalInformation_firstNameInvalidMessage
      ),

    lastName: z
      .string({
        required_error: PersonalInformation_lastNameRequiredErrorMessage
      })
      .min(2)
      .refine(
        (value) => /^[a-zA-Z]+([-'\s]?[a-zA-Z]+)*$/.test(value),
        PersonalInformation_lastNameInvalidMessage
      ),

    phone: z
      .string({
        required_error: PersonalInformation_phoneRequiredErrorMessage
      })
      .refine((value) => /^\d{10}$/.test(value), {
        message: PersonalInformation_phoneInvalidMessage
      }),

    dob: z.string({
      required_error: PersonalInformation_dateOfBirthRequiredErrorMessage
    }).refine((value) => {
      // Check if the value matches the MM/DD/YYYY format
      if (!/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(value)) {
        return false
      }
      // Parse the date from the value
      const parts = value.split('/')
      const year = parseInt(parts[2], 10)
      const month = parseInt(parts[0], 10) - 1 // Months are 0-based
      const day = parseInt(parts[1], 10)

      // Create a Date object with the parsed values
      const selectedDate = new Date(year, month, day)

      // Get today's date
      const today = new Date()

      // Create a Date object for January 1, 1900
      const minDate = new Date(1900, 0, 1)

      // Check if the selected date is not before January 1, 1900 and not after today
      return selectedDate >= minDate && selectedDate <= today
    }, {
      message: PersonalInformation_dateOfBirthInvalidMessage
    })
  })
}

export default validationschema
